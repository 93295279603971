<template>
  <v-app>
    <!--layout-app-app-bar /-->
    <layout-app-drawer :key="keyRender" />
    <v-content>
      <layout-app-view />
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'BaseIndex',
  components: {
  },
  data: () => ({
    expandOnHover: false,
    keyRender: 0
  }),
  methods: {
    canBack() {
      //console.log(this.$route)
      //return window.history.length > 1;
      if (this.$route.name == 'HOME') {
        return false;
      }
      return true;
    },
    toogleDrawer() {
      this.$store.commit('toggle')
    }
  }
}
</script>
<style>
.v-application--wrap {
  background: #f5f5f5;
}

.theme--light .v-content {
  background-color: rgba(255, 255, 255, .94) !important;
}

.theme--light.v-list {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.87);
}

.v-menu__content .theme--light.v-list {
  background: white !important;
  color: rgba(0, 0, 0, 0.87);
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background: transparent !important;
}

.theme--light.v-card {
  background-color: transparent !important;
}

.container--fluid {
  padding: 15px;
}

.v-list-item {
  padding: 0 8px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: white !important;
}

.item-credito div {
  margin-top: 4px;
}

.v-dialog--fullscreen {
  background-color: white !important;
}

.title-app .text-h3 {
  font-size: 1.2em !important;
}

.user-app {
  font-size: 1.2em !important;
}

/*
.theme--light.v-divider {
    border-color: rgba(255, 0, 0, 0.2) !important;
}*/

v-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
</style>